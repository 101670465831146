// $(function () {
//   $(".js-fadeUp-fast").on("inview", function () {
//     $(this).addClass("is-fadeUp-fast");
//   });
// });

// $(function () {
//   $(".js-fadeUp").on("inview", function () {
//     $(this).addClass("is-fadeUp");
//   });
// });

$(function () {

  // $('.js-up').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
  //   if (isInView) {
  //     //要素が見えたときに実行する処理
  //     $(this).addClass("is-up");
  //   } else {
  //     //要素が見えなくなったときに実行する処理
  //     $(this).removeClass("is-up");
  //   }
  // });

  $(".js-fadeUp").on("inview", function () {
    $(this).addClass("is-fadeUp");
  });

  $(".js-fade").on("inview", function () {
    $(this).addClass("is-fadein");
  });

  jQuery( window ).bind( 'scroll', function() {
    scrolled = jQuery( window ).scrollTop();
    weight1 = 0.5;
    weight2 = 0.12;
    jQuery( '.js-parallax1' ).css( 'top', 300 - scrolled * weight1 + 'px' );
  });

});